import MemoTest from "./MemoTest";

export default function Game() {
    return (
        <section className="game-container" id="game">
            <div className="division-shape-top">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1000 100"
                    preserveAspectRatio="none"
                >
                    <path
                        className="elementor-shape-fill-top"
                        d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                    ></path>
                </svg>
            </div>
            <div className="game-title-div">
                <h2 className="game-title">juego crujinola</h2>
                <p className="game-subtitle">
                    Pon a prueba tu destreza con este divertido juego.
                </p>
            </div>

            <div className="game1-containers">
                <div className="left-game-container"></div>

                <div className="center-game-container">
                    <br />
                    <div className="game" id="game"></div>
                </div>

                <div className="right-game-container">
                    <div className="controls">
                        <div className="game-arrow-text">
                            <img
                                src="/img/flecha-02-black.png"
                                alt="flecha hacia abajo"
                                className="arrow-game-img"
                            />
                            <p className="controls-text">mover hacia abajo</p>
                        </div>
                        <div className="game-arrow-text">
                            <img
                                src="/img/flecha-02-black.png"
                                alt="flecha hacia la derecha"
                                className="arrow-game-img arrow-right"
                            />
                            <p className="controls-text">
                                mover hacia la derecha
                            </p>
                        </div>
                        <div className="game-arrow-text">
                            <img
                                src="/img/flecha-02-black.png"
                                alt="flecha hacia la izquierda"
                                className="arrow-game-img arrow-left"
                            />
                            <p className="controls-text">
                                mover hacia izquierda
                            </p>
                        </div>
                        <div className="game-arrow-text">
                            <img
                                src="/img/flecha-02-black.png"
                                alt="rotar"
                                className="arrow-game-img arrow-up"
                            />
                            <p className="controls-text">rotar</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="game2-containers">
                <div className="middle-game-container">
                    <br />
                    <div className="game2" id="game2">
                        <MemoTest />
                    </div>
                </div>
            </div>
            <div className="division-shape-bottom">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1000 100"
                    preserveAspectRatio="none"
                >
                    <path
                        className="elementor-shape-fill"
                        d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                    c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                    c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                    ></path>
                </svg>
            </div>
        </section>
    );
}
