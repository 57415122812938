import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <header className="header">
            <div className="left">
                <img
                    src="/img/hero1.webp"
                    alt="cereales"
                    className="img-left"
                />
            </div>

            <div className="center">
                <Link to="/">
                    <img
                        src="/img/crujinola-logo.svg"
                        alt="Crujinola logo"
                        className="img-center"
                    />
                </Link>
                <p className="center-text">
                    CrujiNola es una deliciosa granola estilo americano con dos
                    versiones de sabor: una clásica con uvas pasas y otra con
                    uvas pasas cubiertas de sabor yogurt.
                </p>
            </div>

            <div className="right">
                <div className="right-container">
                    <div className="right-container">
                        <div className="span-text">
                            <AnimationOnScroll
                                animateIn="animate__bounceIn"
                                delay={700}
                                animateOnce={true}
                            >
                                <span className="ingrediente">
                                    <img src="/img/miel-09.png" alt="Miel" />
                                </span>
                            </AnimationOnScroll>
                            <AnimationOnScroll
                                animateIn="animate__bounceIn"
                                delay={500}
                                animateOnce={true}

                            >
                                <span className="ingrediente">
                                    <img src="/img/Coco-05.png" alt="Coco" />
                                </span>
                            </AnimationOnScroll>
                            <AnimationOnScroll
                                animateIn="animate__bounceIn"
                                delay={800}
                                animateOnce={true}

                            >
                                <span className="ingrediente">
                                    <img src="/img/avena-06.png" alt="Avena" />
                                </span>
                            </AnimationOnScroll>
                        </div>
                        <img
                            src="/img/hero2.webp"
                            alt="Cuchara con cereales"
                            className="img-right"
                        />
                        <div className="span-text span-text2">
                            <AnimationOnScroll
                                animateIn="animate__bounceIn"
                                delay={600}
                                animateOnce={true}

                            >
                                <span className="ingrediente">
                                    <img
                                        src="/img/almendra-07.png"
                                        alt="Almendra"
                                    />
                                </span>
                            </AnimationOnScroll>
                            <AnimationOnScroll
                                animateIn="animate__bounceIn"
                                delay={750}
                                animateOnce={true}

                            >
                                <span className="ingrediente">
                                    <img
                                        src="/img/Uva pasa-08.png"
                                        alt="Uva pasa"
                                    />
                                </span>
                            </AnimationOnScroll>
                        </div>
                    </div>
                </div>
            </div>

            <a href="#products" className="arrowArea">
                <img
                    src="/img/flecha-02.png"
                    alt="flehca de deslizar hacia abajo"
                    className="arrow"
                />
            </a>
        </header>
    );
};

export default Header;
