import { useEffect, useState } from "react";
import Board from "./Board/Board";
const emojiList = [..."💣🧤🎩🌮🎱🌶🍕🦖"];

const Memotest = () => {
    const [shuffledMemoBlocks, setShuffledMemoBlocks] = useState([]);
    const [selectedMemoBlock, setselectedMemoBlock] = useState(null);
    const [animating, setAnimating] = useState(false);
    const [movimientos, setMovimientos] = useState(0);
    const [combinaciones, setCombinaciones] = useState(0);

    useEffect(() => {
        startGame();
    }, []);

    const shuffleArray = a => {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    };

    const startGame = () => {
        let shuffledEmojiList = shuffleArray([...emojiList, ...emojiList]);
        setShuffledMemoBlocks(
            shuffledEmojiList.map((emoji, i) => ({
                index: i,
                emoji,
                flipped: false,
            }))
        );
        setMovimientos(0);
        setCombinaciones(0);
    };

    const handleMemoClick = memoBlock => {
        const flippedMemoBlock = { ...memoBlock, flipped: true };
        let shuffledMemoBlocksCopy = [...shuffledMemoBlocks];
        shuffledMemoBlocksCopy.splice(memoBlock.index, 1, flippedMemoBlock);
        setShuffledMemoBlocks(shuffledMemoBlocksCopy);
        if (selectedMemoBlock === null) {
            // Al clickear en la primer tarjeta.
            setselectedMemoBlock(memoBlock);
        } else if (selectedMemoBlock.emoji === memoBlock.emoji) {
            // Al coincidir 2 tarjetas
            setCombinaciones(combinaciones => combinaciones + 1);
            setselectedMemoBlock(null);
        } else {
            // Al NO coincidir 2 tarjetas
            setAnimating(true);
            setTimeout(() => {
                shuffledMemoBlocksCopy.splice(memoBlock.index, 1, memoBlock);
                shuffledMemoBlocksCopy.splice(
                    selectedMemoBlock.index,
                    1,
                    selectedMemoBlock
                );
                setShuffledMemoBlocks(shuffledMemoBlocksCopy);
                setselectedMemoBlock(null);
                setAnimating(false);
            }, 1000);
        }
        setMovimientos(movimientos => movimientos + 1);
    };

    return (
        <div>
            <div className="info-game2">
                <div>Movimientos: {Math.round(movimientos/2)}</div>
                <div>
                    <button className="btn-restart" onClick={startGame}>
                        <i className="fa-solid fa-rotate-right"></i>
                    </button>
                </div>
            </div>
            <Board
                memoBlocks={shuffledMemoBlocks}
                animating={animating}
                handleMemoClick={handleMemoClick}
            />
            <div className="win-message">
                <p hidden={combinaciones !== 8}>¡Ganaste!</p>
            </div>
        </div>
    );
};

export default Memotest;
