import CookieConsent from "react-cookie-consent";

const Cookies = () => {
    return (
        <>
        <CookieConsent
          buttonText="Aceptar"
          buttonStyle={{ background: "#0056b8", color: "#fff",fontSize: "12px", fontWeight:700, padding: "5px 30px", margin: "0"}}
          expires={150}
          style={{ background: "#fff", color: "#000", fontSize: "10px", fontFamily: "'Montserrat', sans-serif", justifyContent:"space-around", padding: "5px", alignItems: "center" }}
          containerClasses="CookieConsent"
          contentClasses="CookieContent"
        >
            <span className="text-cookie">
            Usamos cookies para asegurarnos que te ofrecemos la mejor experiencia de navegación. Si aceptas o continúas navegando significa que consientes su uso.
            </span>
            </CookieConsent>
        </>
    );
}

export default Cookies;
