const closeFullModal = () => {
    document.getElementById("popup-container").style.display = "none";
};

const openUrls = () => {
    document.getElementById("more-info").style.display = "flex";
    document.getElementById("fraude-txt").classList.add("fraude-txt-opened");
    document.getElementById("showMoreInfoBtn").style.display = "none";
    document.getElementById("dudas-container").style.display = "block";
};

export default function FraudPopup() {
    return (
        <div id="popup-container">
            <div className="popup-fraude" id="information">
                <div className="container">
                    <div className="fraude-txt" id="fraude-txt">
                        <h2 className="popup-title">
                            ¡no sea víctima del fraude!
                        </h2>
                        <p>
                            Les informamos que recientemente personas ajenas y
                            malintencionadas han utilizado nuestro nombre para
                            ofrecer nuestros productos y otros servicios. Para{" "}
                            <span className="bold-txt">
                                {" "}
                                Crujinola - Granola
                            </span>{" "}
                            es importante aclarar que no tenemos relación
                            comercial con las páginas.
                        </p>

                        <div className="more-info" id="more-info">
                            <div>
                                <a>www.productosverdevalle.com</a>
                                <a>www.plantaverdevalle.com</a>
                                <a> www.distribuidordelvalleverde.com</a>
                                <a>www.comercializadorawikabro.com</a>
                            </div>
                            <div>
                                <a>www.vvallesoluciones.com</a>
                                <a>www.verdevallemexico.com</a>
                                <a>www.mayoreoverdevalle.com</a>
                                <a> www.ventasverdevalle.com</a>
                            </div>
                        </div>
                    </div>

                    <div className="buttons-container">
                        <div className="dudas-container" id="dudas-container">
                            <p>
                                {" "}
                                <span className="bold-txt">
                                    Cualquier duda puedes comunicarte sin costo
                                    a{" "}
                                </span>{" "}
                            </p>
                            <small>Nuestra Línea Verde Valle</small>
                            <p>
                                <span className="bold-txt">800 847 0088</span>{" "}
                                <small>
                                    {" "}
                                    - de Lunes a Viernes de 8:30 am a 6 pm
                                </small>
                            </p>
                            <small>Nuestro correo</small>
                            <p>
                                <span className="bold-txt">
                                    atencionaclientes@verdevalle.com
                                </span>
                            </p>
                        </div>
                        <input
                            className="btn-moreinfo"
                            type="submit"
                            id="showMoreInfoBtn"
                            name="mas-info"
                            value="Más información"
                            style={{ display: "block" }}
                            onClick={() => openUrls()}
                        />
                        <button
                            className="btn-cerrar "
                            type="button"
                            name="button"
                            onClick={() => closeFullModal()}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
