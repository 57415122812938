import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./views/Home";
import NotFound from './views/NotFound';
import Privacy from './views/Privacy';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
