import LineaVerdeValle from "./LineaVerdeValle";

const ContactForm = () => {
    
    // Return de ContactForm
    return (
        <section className="contact" id="contact">
            <h2 className="contact-title">contáctanos</h2>

            <div className="phone-container">
                <a href="tel:8008470088" className="alx-link-tel">
                	<LineaVerdeValle />
                </a>
            </div>

            
        </section>
    );
};

export default ContactForm;
