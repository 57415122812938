import BurgerButton from "./BurgerNav";
import { useState } from "react";
import { HashLink } from 'react-router-hash-link';


const Nav = () => {

    const [clicked, setClicked] = useState(true);

    const handleClick = () => {
        setClicked(!clicked);
    }

    return (

        <nav className="nav">
            <div className="nav-responsive">

                {/* Nav Mobile */}
                <div className="burger">
                    <BurgerButton clicked={clicked} handleClick={handleClick} />
                </div>
            </div>


                {/* Nav Desktop */}

                <div className="container-fluid container-nav">
                    <div
                    className="navbar-collapse list-footer list-nav"
                        id="navbarSupportedContent"
                    >
                        <ul className={`nav-ul ${clicked ? 'nav-closed' : 'nav-opened'}`} onClick={setClicked}>

                            <HashLink to="/#products"  className="link li-element">productos</HashLink>
                            <HashLink to="/#buy-section"  className="link li-element">dónde comprar</HashLink>
                            <HashLink to="/#game"  className="link li-element">juega</HashLink>
                            <HashLink to="/#contact"  className="link li-element">contáctanos</HashLink>

                        </ul>
                    </div>
                </div>
        </nav>


    );
}

export default Nav;
