import { useRef } from "react";

const mvImg = (e, ref, isLeaving) => {
    const { clientX, clientY } = e;
    const img = ref.current;
    img.style.transform = isLeaving
        ? "translate(0, 0)"
        : `translate(${(clientX / 15) - 50}px, ${(clientY / 20) - 50}px)`;
};

export default function Buy() {
    const ref = useRef(null);
    return (
        <section
            className="buy-section"
            id="buy-section"
            onMouseMove={e => mvImg(e, ref, false)}
            onMouseLeave={e => mvImg(e, ref, true)}
        >
            <div className="brands">
                <h2 className="brands-title">
                    ¿DÓNDE <br /> COMPRAR?
                </h2>
                <div className="brands-img">

                    <div className="brands-row-container">
                        <a href='https://www.soriana.com/buscar?q=Crujinola&cid=&search-button=' target='_blank' rel="noreferrer">
                            <img
                                src="/img/brands/soriana.png"
                                alt="Soriana logo imagen"
                                className="img-logo"
                                id="first-brand-logo"
                            />
                        </a>
                        <a href='https://super.walmart.com.mx/search?q=crujinola' target='_blank' rel="noreferrer">
                            <img
                                src="/img/brands/walmart.png"
                                alt="Walmart logo imagen"
                                className="img-logo"
                            />
                        </a>
                        <a href='https://www.chedraui.com.mx/crujinola?_q=crujinola&map=ft' target='_blank' rel="noreferrer">
                            <img
                                src="/img/brands/chedraui2.png"
                                alt="Chedraui logo imagen"
                                className="img-logo"
                            />
                        </a>
                    </div>

                    <div className="brands-row-container">
                        <a href='https://www.lacomer.com.mx/lacomer/#!/item-search/287/crujinola/false?p=1&t=0&succId=287&succFmt=100' target='_blank' rel="noreferrer">
                            <img
                                src="/img/brands/mega.png"
                                alt="Mega logo imagen"
                                className="img-logo"
                            />
                        </a>
                        <a href='https://www.heb.com.mx/promociones/granolas/branli-granola-crujinola-330-gr-225398.html' target='_blank' rel="noreferrer">
                            <img
                                src="/img/brands/heb.png"
                                alt="Heb logo imagen"
                                className="img-logo"
                            />
                        </a>
                        <a href='https://www.lacomer.com.mx/lacomer/#!/item-search/287/crujinola/false?p=1&t=0&succId=287&succFmt=100' target='_blank' rel="noreferrer">
                            <img
                                src="/img/brands/lacomer.png"
                                alt="Lacomer logo imagen"
                                className="img-logo"
                            />
                        </a>
                        <a>
                            <img
                                src="/img/brands/ley.png"
                                alt="Ley logo imagen"
                                className="img-logo"
                            />
                        </a>
                    </div>

                </div>
            </div>

            <div className="bowl" id="mv-img">
                <img
                    src="/img/where1.webp"
                    alt="Bowl de granola"
                    className="bowl-img bowl-img1"
                />
                <img
                    src="/img/where2.webp"
                    alt="Gotas de leche salpicando del bowl de granola"
                    className="bowl-img2"
                    ref={ref}
                />
            </div>
        </section>
    );
}
