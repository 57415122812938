import { Nav, Contact, Footer } from "../components";
import { Link } from "react-router-dom";

import "../css/main.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";

const NotFound = () => {
    return (
        <>
            <Nav />
            <div className="not-found">
                <h1 className="title-nf">Oops!</h1>
                <p className="text-nf">404 - página no encontrada</p>
                <p className="mini-text-nf">
                    La página que estás buscando pudo haber sido removida <br />
                    o está temporalmente inhabilitada.
                </p>
                <Link to="/" className="back-home">
                    Volver al inicio
                </Link>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default NotFound;
