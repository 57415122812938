import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="img-footer-container">
                <Link to="/">

                    <img
                        src="/img/Crujinola logo blanco-04.png"
                        alt="Crujinola logo blanco"
                        className="img-footer"
                    />
                </Link>
                </div>
                <div className="list-footer">
                    <ul className="nav-ul footer-nav">
                        <HashLink to="/#products"  className="link li-element">productos</HashLink>
                        <HashLink to="/#buy-section"  className="link li-element">dónde comprar</HashLink>
                        <HashLink to="/#game"  className="link li-element">juega</HashLink>
                        <HashLink to="/#contact"  className="link li-element">contáctanos</HashLink>
                    </ul>
                </div>
            </div>
            <p className="copy-text">
                &copy; 2022 Copyright Verde Valle | <Link to='/privacy'>Aviso de Privacidad</Link>
            </p>
        </footer>
    );
}
