import {
    Nav,
    Header,
    Products,
    Buy,
    Game,
    Contact,
    Footer,
    FraudPopup,
    Cookies

} from "../components";
import { useEffect } from 'react';

import "../css/main.css";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "animate.css/animate.min.css";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Nav />
            <Header />
            <Products />
            <Buy />
            <Game />
            <Contact />
            <Footer />
            <FraudPopup />
            <Cookies />
        </>
    );
};

export default Home;
