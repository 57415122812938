export default function Products() {
    return (
        <>
            <section
                className="products-section products-section1"
                id="products"
            >
                <div className="flavor-ingredients">
                    <img
                        src="/img/products/i1-1.png"
                        alt="ingrediente: Almendra"
                        className="ingredient"
                    />
                    <img
                        src="/img/products/i1-2.png"
                        alt="ingrediente: Miel"
                        className="ingredient"
                    />
                    <img
                        src="/img/products/i1-3.png"
                        alt="ingrediente: Coco"
                        className="ingredient"
                    />
                </div>

                <div className="product">
                    <div className="product-img-div">
                        <img
                            src="/img/products/product1.webp"
                            alt=" GRANOLA ESTILO AMERICANA"
                            className="product-img"
                        />
                    </div>
                    <div className="product-text-div">
                        <h1 className="product-title">
                            granola estilo americana
                        </h1>
                        <p className="product-text">
                            Para los amantes de lo crujiente, esta deliciosa
                            granola te dejará encantado con su irresistible
                            mezcla de avena, miel, almendra, coco y uva pasa.
                        </p>
                    </div>
                </div>

                <div className="nutritional-facts">
                    <img
                        src="/img/products/table1.svg"
                        alt="valor nutricional"
                        className="nutritional-img"
                    />
                </div>
            </section>

            <section className="products-section products-section2">
                <div className="flavor-ingredients">
                    <img
                        src="/img/products/i2-1.png"
                        alt="ingrediente: Almendra"
                        className="ingredient"
                    />
                    <img
                        src="/img/products/i2-2.png"
                        alt="ingrediente: Miel"
                        className="ingredient"
                    />
                    <img
                        src="/img/products/i2-3.png"
                        alt="ingrediente: Coco"
                        className="ingredient"
                    />
                </div>

                <div className="product">
                    <div className="product-img-div">
                        <img
                            src="/img/products/product2.webp"
                            alt="GRANOLA CON YOGURT"
                            className="product-img"
                        />
                    </div>
                    <div className="product-text-div">
                        <h1 className="product-title product-title2">
                            granola con yogurt
                        </h1>
                        <p className="product-text">
                            Nuestra mezcla clásica con un toque divertido. Esta
                            granola tiene todo lo exquisito del estilo
                            americano, pero con un toque cremoso de uvas pasas
                            sabor yogurt.
                        </p>
                    </div>
                </div>

                <div className="nutritional-facts">
                    <img
                        src="/img/products/table2.svg"
                        alt="valor nutricional"
                        className="nutritional-img"
                    />
                </div>
                <div className='division-shape-bottom-products'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="elementor-shape-fill-products" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                    </svg>
                </div>
            </section>
        </>
    );
}
